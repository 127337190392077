import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import ShowroomSection from "../components/showroom-section";
import ServiceHero from "../components/service-hero";
import OpeningBanner from "../components/opening-banner";
import ThreeImageSection from "../components/three-image-section";
import Reviews from "../components/reviews";
import PriceListSection from "../components/price-list-section";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-ntml-parser";

const GaragesPage = () => {
	const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: wpMediaItem(title: { eq: "tops-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "garages" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        garagesPageFieldGroups {
          garagesBannerSection {
            heading
            description
            cta1 {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          garagesTextSection {
            heading
            description
          }
          garagesFaqSection {
            heading
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          garagesTwoColumnSection2 {
              description
              heading
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
          }
          garagesServiceSection {
            heading
            description
            cta {
              title
              target
              url
            }
            images {
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          garagesCtaSection1 {
            heading
            description
            cta {
              title
              target
              url
            }
          }
          garagesReviewsSection {
            heading
          }
          garagesOpeningHoursSection {
            heading
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, garagesPageFieldGroups },
  } = data;

  const {
    garagesBannerSection,
    garagesCtaSection1,
    garagesFaqSection,
    garagesOpeningHoursSection,
    garagesReviewsSection,
    garagesServiceSection,
    garagesTextSection,
    garagesTwoColumnSection2,
  } = garagesPageFieldGroups;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Garages",
				item: {
					url: `${siteUrl}/garages`,
					id: `${siteUrl}/garages`,
				},
			},
		],
	};

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
    "@id": `https://www.topsgardenbuildings.com/garages`,
    image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.topsgardenbuildings.com/garages`,
    sku: `https://www.topsgardenbuildings.com/garages`,
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
		name: "Tops Garden Buildings",
		url: "https://www.topsgardenbuildings.com",
		brand: {
			"@type": "Organization",
      logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
			name: "Tops Garden Buildings",
			url: "https://www.topsgardenbuildings.com",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: "125",
			ratingValue: "4.9",
			bestRating: "5",
			worstRating: "1",
		},

		review: {
			"@type": "Review",
			url: "https://www.topsgardenbuildings.com",
			datePublished: `${data.reviews.reviewDate}`,
			reviewBody: `${data.reviews.reviewContent}`,
			author: {
				"@type": "Person",
				name: `${data.reviews.reviewAuthor}`,
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "4.9",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Tops Garden Buildings",
				sameAs: "https://www.topsgardenbuildings.com",
			},
		},
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/garages`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />
			  <div>
        <Layout>
          {garagesBannerSection &&
            !checkPropertiesForNull(garagesBannerSection, ["heading"]) && (
              <ServiceHero
                backgroundImage={
                  garagesBannerSection.backgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                title={garagesBannerSection?.heading}
                description={
                  <SafeHtmlParser
                    htmlContent={garagesBannerSection?.description}
                  />
                }
                ctaButton={garagesBannerSection?.cta1}
                image={garagesBannerSection?.image.node}
              />
            )}

          {garagesTextSection &&
            !checkPropertiesForNull(garagesTextSection, ["heading"]) && (
              <section className="py-5 py-lg-7">
                <Container>
                  <Row>
                    <Col lg={4}>
                      <h2 className=" fs-1 mb-4 text-primary  ">
                        {garagesTextSection?.heading}
                      </h2>
                    </Col>
                    <Col lg={8}>
                      <SafeHtmlParser
                        htmlContent={garagesTextSection?.description}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

          {garagesTwoColumnSection2 &&
            !checkPropertiesForNull(garagesTwoColumnSection2, [
              "leftColumn",
            ]) && (
              <PriceListSection
                bgColour="bg-light-grey"
                mobileImageHeight="20rem"
                title={garagesTwoColumnSection2?.heading}
                text={
                  <SafeHtmlParser
                    htmlContent={
                      garagesTwoColumnSection2?.description
                    }
                  />
                }
                imageHeight="25rem"
                image={
                  garagesTwoColumnSection2?.image?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={
                  garagesTwoColumnSection2?.image?.node?.altText
                }
              />
            )}

          {garagesServiceSection &&
            !checkPropertiesForNull(garagesServiceSection, [
              "heading",
            ]) && (
              <ThreeImageSection
                title={garagesServiceSection?.heading}
                text={
                  <div className="text-white">
                    <SafeHtmlParser
                      htmlContent={garagesServiceSection?.description}
                    />
                  </div>
                }
                imageHeight="18rem"
                images={garagesServiceSection?.images}
                ctaButton={garagesServiceSection.cta}
              />
            )}

          <Faq
            imageHeight="25rem"
            page="Home Offices"
            image={
              garagesFaqSection?.image?.node?.localFile.childImageSharp
                .gatsbyImageData
            }
            imageAlt={garagesFaqSection?.image?.node?.altText}
            heading={garagesFaqSection?.heading}
          />
          <Reviews
            topPadding="pt-5 pt-md-0"
            title={garagesReviewsSection?.heading}
          />

          <OpeningBanner heading={garagesOpeningHoursSection?.heading} />

          <ShowroomSection
            title={garagesCtaSection1?.heading}
            description={
              <SafeHtmlParser
                htmlContent={garagesCtaSection1?.description}
              />
            }
            ctaButton={garagesCtaSection1?.cta}
          />
        </Layout>
      </div>
		</>
	);
};

export default GaragesPage;
